// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-interness-theme-default-src-pages-404-js": () => import("./../../../node_modules/@interness/theme-default/src/pages/404.js" /* webpackChunkName: "component---node-modules-interness-theme-default-src-pages-404-js" */),
  "component---node-modules-interness-theme-default-src-pages-contact-js": () => import("./../../../node_modules/@interness/theme-default/src/pages/contact.js" /* webpackChunkName: "component---node-modules-interness-theme-default-src-pages-contact-js" */),
  "component---node-modules-interness-theme-default-src-pages-thanks-js": () => import("./../../../node_modules/@interness/theme-default/src/pages/thanks.js" /* webpackChunkName: "component---node-modules-interness-theme-default-src-pages-thanks-js" */),
  "component---node-modules-interness-web-core-src-pages-datenschutz-social-mdx": () => import("./../../../node_modules/@interness/web-core/src/pages/datenschutz-social.mdx" /* webpackChunkName: "component---node-modules-interness-web-core-src-pages-datenschutz-social-mdx" */),
  "component---node-modules-interness-web-core-src-pages-info-js": () => import("./../../../node_modules/@interness/web-core/src/pages/---info.js" /* webpackChunkName: "component---node-modules-interness-web-core-src-pages-info-js" */),
  "component---node-modules-interness-web-core-src-templates-page-legal-js": () => import("./../../../node_modules/@interness/web-core/src/templates/page/legal.js" /* webpackChunkName: "component---node-modules-interness-web-core-src-templates-page-legal-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leistungsspektrum-ernaehrungsberatung-mdx": () => import("./../../../src/pages/leistungsspektrum/ernaehrungsberatung.mdx" /* webpackChunkName: "component---src-pages-leistungsspektrum-ernaehrungsberatung-mdx" */),
  "component---src-pages-leistungsspektrum-flugmedizin-mdx": () => import("./../../../src/pages/leistungsspektrum/flugmedizin.mdx" /* webpackChunkName: "component---src-pages-leistungsspektrum-flugmedizin-mdx" */),
  "component---src-pages-leistungsspektrum-reisemedizin-mdx": () => import("./../../../src/pages/leistungsspektrum/reisemedizin.mdx" /* webpackChunkName: "component---src-pages-leistungsspektrum-reisemedizin-mdx" */),
  "component---src-pages-leistungsspektrum-sportmedizin-mdx": () => import("./../../../src/pages/leistungsspektrum/sportmedizin.mdx" /* webpackChunkName: "component---src-pages-leistungsspektrum-sportmedizin-mdx" */),
  "component---src-pages-leistungsspektrum-tauch-tu-mdx": () => import("./../../../src/pages/leistungsspektrum/tauch-tu.mdx" /* webpackChunkName: "component---src-pages-leistungsspektrum-tauch-tu-mdx" */),
  "component---src-pages-team-mdx": () => import("./../../../src/pages/team.mdx" /* webpackChunkName: "component---src-pages-team-mdx" */)
}

